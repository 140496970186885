@font-face {
  font-family: 'Regular';
  font-weight: 400;
  src: local('Spoqa Han Sans Neo Regular'),
    url(./assets/fonts/SpoqaHanSansNeo-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Medium';
  font-weight: 550;
  src: local('Spoqa Han Sans Neo Medium'),
    url(./assets/fonts/SpoqaHanSansNeo-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Bold';
  font-weight: 700;
  src: local('Spoqa Han Sans Neo Bold'),
    url(./assets/fonts/SpoqaHanSansNeo-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

